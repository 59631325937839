<template>
  <div>
    <div class="content">
      <div class="cont_top">
        <img src="~@/assets/jiagongdan.png" class="imgs" />
        <div class="zs">知识加工</div>
        <!-- s搜索 -->
        <!-- <el-input v-model="valueData" placeholder="请输入关键词">
          <i class="el-input__icon el-icon-search" slot="suffix" style="cursor:pointer;"></i>
        </el-input>-->
        <span class="bc">
                      <!-- @keyup.enter="init(pageSize, 0)" -->
          <el-input
            placeholder="请输入检索关键词"
            @change="init(pageSize, 0)"
            v-model="valueData"
            class="input-with-select"
          >
            <el-select
              v-model="type"
              @change="init(pageSize, 0)"
              slot="prepend"
              placeholder="全部"
              style="  width: 130px;"
            >
              <el-option label="全部" value></el-option>
              <el-option label="词条" value="2"></el-option>
              <el-option label="数字化产品" value="1"></el-option>
              <el-option label="纸质化加工" value="0"></el-option>
            </el-select>
            <!-- <el-button
              @click="init(pageSize, 0)"
              slot="append"
              icon="el-icon-search"
              style="font-size:27px;
                     color: rgb(14, 87, 156);
                     font-weight: 1000;width:70px;margin-right:0px;"
            ></el-button>-->
            <el-button
              class="js"
              slot="append"
              @click="init(pageSize, 0)"
              style="font-size:15px;
                     font-weight: 1000;
                     width:70px;
                     "
            >检索</el-button>
          </el-input>
          <!-- <el-button class="js" @click="handsearch()">检索</el-button> -->
        </span>
        <span class="renwu">
          <el-button class="downa" @click="fbrw()">+ 发布任务</el-button>
          <span v-if="ptdown" class="hei">
            <el-button class="downa" @click="ptdowns('发布数字产品')">发布数字产品</el-button>
            <el-button class="downa" @click="ptdowns('发布词条加工')">发布词条加工</el-button>
            <el-button class="downa" @click="ptdowns('发布纸质加工')">发布纸质加工</el-button>
          </span>
          <!-- <span class="hei" v-if="!ptdown"></span> -->
        </span>
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData" @row-click="jump" style="width: 100%">
        <!-- :default-sort="{prop: 'totalmoney', order: 'descending'}" -->
        <el-table-column prop="taskname" label="任务名称"></el-table-column>
        <el-table-column prop="username" label="发布者"></el-table-column>
        <el-table-column label="任务类型">
          <template slot-scope="scope">
            <span v-if="scope.row.tasktype==0">纸质化加工</span>
            <span v-if="scope.row.tasktype==1">数字化产品</span>
            <span v-if="scope.row.tasktype==2">词条</span>
          </template>
        </el-table-column>
        <el-table-column label="总酬劳" prop="totalmoney">
          <template slot-scope="scope">
            <span>{{scope.row.totalmoney}}知识币</span>
          </template>
        </el-table-column>
        <el-table-column prop="totaldays" label="任务工期">
          <template slot-scope="scope">
            <span>{{scope.row.totaldays}}天</span>
          </template>
        </el-table-column>
        <el-table-column label="任务状态">
          <template slot-scope="scope">
            <span>{{scope.row.status}}</span>
          </template>
        </el-table-column>
        <el-table-column label="任务发布时间" width="200">
          <template slot-scope="scope">
            <span>{{scope.row.issueTime}}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-nav">
        <el-pagination
          background
          layout="total,prev, pager, next"
          :hide-on-single-page="hidePageNav"
          :total="totlePage"
          :page-size="pageSize"
          @prev-click="currentPage"
          @next-click="currentPage"
          @current-change="currentPage"
        ></el-pagination>
      </div>
    </div>
    <!-- 平台和约书 -->
    <div class="heyueshu" v-if="heyueshu">
      <div class="heyue_center">
        <div class="heyue_top">
          <span class="yin1" @click="close"></span>
        </div>
        <!-- 合约正文 -->
        <div class="zhengwen">
          <!-- <div class="title">知识加工合约</div>
          <div class="bt">1.合约基础条款：</div>
          <div
            class="cont mar_b"
          >同的条款是合同中经双方当事人协商一致、规定双方当事人权利义务的具体条文。合同的条即合同的内容。合同的权利义务，除法律规定的以外，主要由合同的条款确定。合同的条款是否齐备、准确，决定 了合同能否成立、生效以及能否顺利地履行、实现订立合同的目的。</div>
          <div class="bt">2.合约基本规则：</div>
          <div
            class="cont"
          >永续合约与传统的期货合约相似,是传统期货合约的一个衍生产品。无论是熊市或者牛市,用户都可以在永续合约中通过做多或做空来实现盈利。用户需要自己指定下单的价格和数量。限价委托规定了用户愿意买 的最高价格或愿意卖的最低价格。</div>-->
          <!-- <div>
            <span>甲方:智慧网</span>
            <span>乙方:{{user_name}}</span>
          </div>-->
          <!-- <div class="ty">
            <el-button type="primary" @click="TY()" :disabled="disabled">
              同意
              <span v-if="times>0">( {{times}}s )</span>
            </el-button>
          </div>-->
          <div class="gundongtiao">
            <h1 style="text-align: center;width:100%;position: relative;top:-30px">知识加工合约</h1>
            <h3>一、知识加工流程</h3>
            <h3 style="padding-left:15px;">1.1 负责人竞标</h3>
            <p style="padding-left:32px;text-align: justify;">
              知识加工任务流程是由任务发布者发布加工任务，设置加工要求，并预付加工所需知识币；任务发布成后，将由任务负责人参与竞标，
              负责人根据整体任务要求将任务划分为多个子任务包，并按劳分配知识币，提交划分结果；任务划分完成后，
              任务发布者将会收到消息通知，并决定任务负责人。
            </p>
            <h3 style="padding-left:15px;">1.2 负责人工作内容</h3>
            <p style="padding-left:32px;text-align: justify;">1.负责人需要将整体任务划分未多个子任务包，并按劳分配知识币；</p>
            <p style="padding-left:32px;text-align: justify;">2.若存在团队成员退出，需要将退出成员负责人的任务协调给团队其他成员完成；</p>
            <p style="padding-left:32px;text-align: justify;">3.负责人需要对知识成果进行整理或审核校对；</p>
            <p style="padding-left:32px;text-align: justify;">4.最终完成的知识成果需要由负责人进行提交。</p>
            <h3>二、知识加工条约</h3>
            <h3 style="padding-left:15px;">2.1 按时完成加工任务</h3>
            <p
              style="padding-left:32px;text-align: justify;"
            >任务负责人及其团队成员在加工工期内完成任务，将获得在知识成果提交后的7个工作日之后获得知识币。</p>
            <h3 style="padding-left:15px;">2.2 未按时完成加工任务</h3>
            <p style="padding-left:32px;text-align: justify;">
              任务负责人及其团队成员在加工工期截止后：在24小时之内，提交知识成果属于逾期，违约扣除团队加工者10%的知识币；在24小时之后，
              仍未提交知识成果，属于违约，团队将不能在获得加工酬劳。
            </p>
            <h3 style="padding-left:15px;">2.3 发布任务者取消任务</h3>
            <p
              style="padding-left:32px;text-align: justify;"
            >任务发布者可以在任务启动前取消任务，知识币全部退还；启动后就将不可取消，若继续取消，知识币将不给予退还。</p>
          </div>
          <div class="ty">
            <el-button type="primary" @click="TY()" :disabled="disabled">
              同意
              <span v-if="times>0">( {{times}}s )</span>
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import axios from "axios";
import { dateType, YearMMdd } from "@/js/common/index.js";
import { INSERT, QUERY, DELETE, UPDATE, QUERYED } from "@/services/dao.js";
import { Alert } from "element-ui";
export default {
  data() {
    return {
      task: "", //类型
      times: 10, //倒计时
      heyueshu: false, //合约
      type: "", //选中的查询类型
      valueData: "", //查询内容
      ptdown: false, //是否展开发布任务
      tableData: [], /////查询结果
      pageNum: 1, ////当前页数
      pageSize: 20, //每页条数
      totlePage: 0, ///总条数
      hidePageNav: false,
      disabled: true, //禁用
      user_name: this.$store.state.userinfo.name //用户名
    };
  },
  created() {
    // this.init1(); //
    this.init(this.pageSize, 0); //刷新
  },

  methods: {
    jump(row) {
      this.$router.push({
        path: "/taskDetail",
        query: {
          id: row.id,
          type: row.tasktype
        }
      });
    },

    formatter(row, column) {
      // console.log(row);
      // return row.address;
    },
    // changeSort(val){
    //   console.log(val)
    // },
    //前一页
    // prevPage(val) {
    //   console.log(val,123);
    //   this.pageNum = val - 1;
    //   this.init(this.pageSize, this.pageSize * this.pageNum);
    // },
    // //下一页
    // nextPage(val) {
    //   this.pageNum = val + 1;
    //   this.init(this.pageSize, this.pageSize * this.pageNum);
    // },
    //当前页
    currentPage(val) {
       this.pageNum = val - 1;
      // this.pageNum = val;
      this.init(this.pageSize * this.pageNum, this.pageSize * this.pageNum);
      // this.init(this.pageSize, this.pageSize * this.pageNum);
    },
    // init1() {
    //   const formData = new FormData();
    //   formData.append("taskType", this.type);
    //   formData.append("taskName", this.valueData);
    //   formData.append("pageIndex", 1);
    //   formData.append("pageSize", 10);
    //   axios
    //     .post(this.api.LoginURL.concat("/common/jgSearch"), formData)
    //     .then(res => {
    //       console.log("成功", res.data);
    //       // for (let j = 0; j < res.data.totalCount; j++) {
    //       //   res.data.content[j].issueTime = dateType(
    //       //    Number( res.data.content[j].issueTime)
    //       //   );
    //       //   if (res.data.content[j].status == 0) {
    //       //     res.data.content[j].status = "未启动";
    //       //   }
    //       //   if (res.data.content[j].status == 1) {
    //       //     res.data.content[j].status = "已逾期";
    //       //   }
    //       //   if (res.data.content[j].status == 2) {
    //       //     res.data.content[j].status = "加工中";
    //       //   }
    //       //   if (res.data.content[j].status == 3) {
    //       //     res.data.content[j].status = "已完成";
    //       //   }
    //       //   if (res.data.content[j].status == 4) {
    //       //     res.data.content[j].status = "已取消";
    //       //   }
    //       //   if (res.data.content[j].status == 5) {
    //       //     res.data.content[j].status = "已违约";
    //       //   }
    //       // }
    //       console.log("赋值前", res.data.content);
    //       this.tableData = res.data.content;
    //       console.log("赋值hou", JSON.stringify(this.tableData));
    //       // this.totlePage =  res.data.totalCount; //总条数
    //     })
    //     .catch(err => {
    //       //console.log("失败", err);
    //     });
    // },

    async init(limit, offset) {
      this.tableData = []; //清空查询
      let info = null;
      if (this.type == "") {
        info = await QUERYED(
          "post",
          "",
          'JGSearch(order_by: {issueTime: desc},where: {taskname: {_like: "%' +
            this.valueData +
            '%"}},limit: ' +
            limit +
            ", offset: " +
            offset +
            ') {id tasktype taskname username userid type totalmoney totaldays status issueTime} JGSearch_aggregate(where: {taskname:{_like: "%' +
            this.valueData +
            '%"}}) {  aggregate {    count  }   }'
        );
      } else {
        info = await QUERYED(
          "post",
          "",
          'JGSearch(order_by: {issueTime: desc},where: {taskname: {_like: "%' +
            this.valueData +
            '%"},tasktype: {_eq: ' +
            this.type +
            "}},limit:" +
            limit +
            ",offset:" +
            offset +
            ') {id tasktype taskname username userid type totalmoney totaldays status issueTime} JGSearch_aggregate(where: {taskname:{_like: "%' +
            this.valueData +
            '%"},tasktype: {_eq: ' +
            this.type +
            "}}) {  aggregate {    count  }   }"
        );
      }
      for (let j = 0; j < info.data.JGSearch.length; j++) {
        info.data.JGSearch[j].issueTime = YearMMdd(
          info.data.JGSearch[j].issueTime
        );
        //        console.log("之前",info.data.JGSearch[j].issueTime)
        //      var mydate= new Date(info.data.JGSearch[j].issueTime);
        // // mydate.setDate(mydate.getDate()+1); //当前时间加1天
        //  console.log("之后",dateType( mydate.setDate(mydate.getDate()+1)))
        if (info.data.JGSearch[j].status == 0) {
          info.data.JGSearch[j].status = "未启动";
        }
        if (info.data.JGSearch[j].status == 1) {
          info.data.JGSearch[j].status = "已逾期";
        }
        if (info.data.JGSearch[j].status == 2) {
          info.data.JGSearch[j].status = "加工中";
        }
        if (info.data.JGSearch[j].status == 3) {
          info.data.JGSearch[j].status = "已完成";
        }
        if (info.data.JGSearch[j].status == 4) {
          info.data.JGSearch[j].status = "已取消";
        }
        if (info.data.JGSearch[j].status == 5) {
          info.data.JGSearch[j].status = "已违约";
        }
        // if (info.data.JGSearch[j].tasktype == 0) {
        //   info.data.JGSearch[j].tasktype = "纸质化加工";
        // }
        // if (info.data.JGSearch[j].tasktype == 1) {
        //   info.data.JGSearch[j].tasktype = "数字化产品";
        // }
        // if (info.data.JGSearch[j].tasktype == 2) {
        //   info.data.JGSearch[j].tasktype = "词条";
        // }
      }
      this.tableData = info.data.JGSearch;
      this.totlePage = info.data.JGSearch_aggregate.aggregate.count; //总条数
      //  }
    },
    //发布任务
    fbrw() {
      this.ptdown = !this.ptdown;
    },
    // 关闭?
    close() {
      this.heyueshu = false;
      clearInterval(this.timer); //清除定时器
    },
    ///同意
    TY() {
      if (this.times == 0) {
        if (this.task == "发布纸质加工") {
          this.$router.push({
            path: "/digitization"
          });
        }
        if (this.task == "发布数字产品") {
          this.$router.push({
            path: "/digitalProducts"
          });
        }
        if (this.task == "发布词条加工") {
          this.$router.push({
            path: "/releaseEntry"
          });
        }
      }
    },
    ///倒计时
    ptdowns(name) {
      if (this.$store.state.userinfo.id) {
        ////判断用户是否登录
        this.task = name; ///存储选中类型
        this.times = 6;
        this.disabled = true;
        this.heyueshu = true; //显示合约书
        this.timer = setInterval(() => {
          this.times--;
          if (this.times <= 0) {
            this.disabled = false;
            // this.heyueshu = false; //关闭合约书
            clearInterval(this.timer); //清除定时器
          }
        }, 1000);
      } else {
        this.$message({
          message: "请您先登录！",
          type: "warning"
        });
      }
    }
  }
};
</script>

<style scoped>
/* 滚动条 */
.gundongtiao {
  overflow-y: auto;
  height: 500px;
  width: 95%;
  padding: 10px;
  /* border: 1px solid red; */
  display: inline-block;
}
.title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  text-align: center;
  height: 40px;
  line-height: 40px;
  margin-bottom: 50px;
}
.mar_b {
  margin-bottom: 20px;
}
.ty {
  margin-top: 20px;
  text-align: center;
}
/* 标题 */
.bt {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #000000;
  height: 30px;
  line-height: 30px;
}
/* 内容 */
.cont {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #000000;
  line-height: 25px;
}
/* .zhengwen > div {
  width: 100%;
} */
/* .zhengwen > div > span {
  margin-top: 20px;
  width: 50%;
  text-align: center;
  display: inline-block;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
} */
/* 引用 */
.yin1 {
  display: inline-block;
  background-image: url("~@/assets/quxiao.png");
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border: 1px solid #dcdcdc;
  border-radius: 5%;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
  left: 8px;
  top: 2px;
}
.zhengwen {
  width: 575px;
  /* display: inline-block; */
  padding: 0px 0px 0px 10px;
}
.heyue_top {
  width: 100%;
  /* height: 18px; */
  padding: 0px 0px 10px 0px;
  /* border: 1px solid red; */
  display: inline-block;
  text-align: right;
}
.heyue_center {
  width: 580px;
  height: 100%;
  background-color: #ffffff;
}
.heyueshu {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  background-image: url("~@/assets/jiagong.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  max-width: 100%;
  max-height: 100%;
  /* 上右下左 */
  padding: 160px 0px 160px 260px;
  height: 100px;
  /* position: relative; */
  /* border:1px solid red */
}
.cont_top {
  /* background-color: #ffffff; */
  /* overflow: hidden; */
  /* border: 1px solid red; */
}
.bc {
  /* display: inline-block; */
  vertical-align: top;
  /* background-color: #ffffff; */
}
.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
  /* height:50px */
}

.img {
  line-height: 20px;
}
.content img {
  width: 30px;
  height: 35px;
  max-width: 100%;
  max-height: 100%;
  float: left;
  margin-right: 15px;
  position: relative;
  top: -5px;
}
.el-icon-search {
  font-size: 20px;
  color: rgb(14, 87, 156);
  font-weight: bold;
  /* border:1px solid red */
}
.zs {
  font-size: 25px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #fefffd;
  float: left;
  line-height: 25px;
}
.el-input {
  width: 45%;
  margin-left: 3%;
}

.content >>> .el-input__inner {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.js {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  /* position: relative;
  top:10px; */
}
.downa {
  display: inline-block;
  width: 120px;
  color: #1b68c5;
  font-weight: 700;
  /* margin-left: 30px; */
}
.downa{
  border-radius: 0;
}
.content >>> .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #fdfdfd;
}
.el-checkbox {
  color: #fdfdfd;
}
.el-checkbox {
  margin-right: 50px;
}
.table {
  /* border-top: 8px solid #6887c7; */
  padding: 0px 150px 0px 150px;
}
.head {
  float: right;
  color: #b7c9dd;
  margin: 10px;
}
.el-dialog__wrapper >>> .el-dialog__footer {
  padding: 10px 20px 20px;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.el-dialog__wrapper >>> .el-dialog {
  background-color: #f6f6f6;
}

.hyl {
  font-weight: 800;
}
.qy {
  overflow: hidden;
  margin-top: 50px;
}
.qyfw {
  margin-left: 150px;
  font-weight: 800;
}
.hei {
  /* height:120px; 
   display: inline-block; */
}
.renwu {
  width: 9%;
  color: #1b68c5;
  font-weight: 700;
  /* border: 1px solid red; */
  display: inline-block;
  margin-left: 30px;
  /* height:120px; */
}
.el-button + .el-button {
  margin-left: 0px;
}
.page-nav {
  width: 100%;
  text-align: right;
  background-color: #ffffff;
  /* height: 30px; */
  padding: 10px 0px 5px 0px;
  /* font-size: 15px; */
  /* border: 1px solid red; */
  /* display: inline-block; */
}
.el-table::before {
  z-index: auto;
  /* background-color: red; */
}
</style>
 